import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import pic from "../../content/assets/selfie_with_marley_paige_meadows-300x225.jpg"

function PrivacyPage() {
  return (
    <Layout>
      <SEO
        title="Privacy policy"
      />

      <section className="max-w-screen-lg m-auto  mt-20 ">
        <h1 className="text-center p-3 mt-5 mb-4 text-2xl font-bold text-white font-family-opensans">
          Privacy Policy
        </h1>
        <img
          className="float-right w-1/2 md:w-1/3 rounded-md m-5"
          alt="hard at work"
          src={pic}
        />
        <p className="p-3 mb-4 text-lg md:text-base text-white font-family-opensans">
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
        </p>{" "}
        <p className="p-3 mb-4 text-lg md:text-base text-white font-family-opensans">
          We do not collect any data.
        </p>
      </section>
    </Layout>
  )
}

export default PrivacyPage
